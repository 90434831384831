import React, { useCallback, MouseEventHandler } from "react";
import { Box, Button, Card } from "grommet";
import { NlndPageProps } from "./types";
import { useNavigate } from "@reach/router";
import PageWrapper from "./PageWrapper";

const LanderMenuItem = ({
  label,
  onClick,
}: {
  label: string;
  onClick: MouseEventHandler;
}) => (
  <Card
    direction="row"
    margin="20px"
    justify="center"
    background="light-3"
    height="80px"
  >
    <Button onClick={onClick} fill size="large">
      <Box direction="row" justify="center">
        {label}
      </Box>
    </Button>
  </Card>
);

const Page = ({ postId, user }: NlndPageProps<{ postId: string }>) => {
  const navigate = useNavigate();

  return (
    <Box pad="20px">
      <LanderMenuItem
        label="View Conversation"
        onClick={() => navigate(`/nlndx/conversation/${postId}`)}
      />
      <LanderMenuItem
        label="Get Updates"
        onClick={() => navigate(`/nlndx/subscription/${postId}`)}
      />
      <LanderMenuItem
        label="Manage Subscriptions"
        onClick={() => navigate(`/nlndx/subscription`)}
      />
    </Box>
  );
};

const WrappedPage = (props: NlndPageProps<{ postId: string }>) => (
  <PageWrapper {...props} title="Explore Post">
    <Page {...props} />
  </PageWrapper>
);

export default WrappedPage;
