import React from "react";
import { PageProps } from "gatsby";
import { Router } from "@reach/router";
import {
  ThemeType,
  Grommet,
} from "grommet";
import { Helmet } from "react-helmet";

import useUser, { LoginOverlay } from "../../components/login";
import PostLander from "../../components/nlndx/PostLander";
import Default from "../../components/nlndx/Home";
import Conversation from "../../components/nlndx/Conversation";
import Subscription from "../../components/nlndx/Subscription";
import SubscriptionList from "../../components/nlndx/SubscriptionList";

const customTheme: ThemeType = {
  global: {
    font: {
      family: "Helvetica",
    },
  },
  table: {
    body: {
      align: "center",
      pad: { horizontal: "large", vertical: "xsmall" },
      border: "horizontal",
    },
    footer: {
      align: "start",
      border: undefined,
      pad: { horizontal: "large", vertical: "small" },
      verticalAlign: "bottom",
    },
    header: {
      align: "center",
      border: "bottom",
      fill: "horizontal",
      pad: { horizontal: "large", vertical: "xsmall" },
      verticalAlign: "bottom",
      background: {
        color: "neutral-3",
        opacity: 0.8,
      },
    },
  },
};

const Index = (pageProps: PageProps) => {
  const user = useUser();

  return (
    <Grommet theme={customTheme}>
      <Helmet>
        <title>Explore Post</title>
        <link
          type="text/css"
          rel="stylesheet"
          href="https://www.gstatic.com/firebasejs/ui/4.7.1/firebase-ui-auth.css"
        />
      </Helmet>
      <Router basepath="/nlndx">
        <Default path="/" user={user} />
        <Subscription path="subscription/:postId" user={user} />
        <SubscriptionList path="subscription" user={user} />
        <PostLander path="post/:postId" user={user} />
        <Conversation path="conversation/:postId" user={user} />
      </Router>
      <LoginOverlay context={user} />
    </Grommet>
  );
};

export default Index;
