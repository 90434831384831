import React, { useState } from "react";
import { Anchor, Header, Main, Nav } from "grommet";
import { LinkPrevious, Login, Logout } from "grommet-icons";
import { NlndPageProps } from "./types";

const Wrapper = ({
  children,
  navigate,
  title,
  user: userContext,
}: NlndPageProps<{ children: React.ReactChild; title: React.ReactNode }>) => {
  const { user, login, logout } = userContext;
  const [backDisabled, setBackDisabled] = useState(false);

  return (
    <>
      <Header background="neutral-3" pad="medium" justify="between">
        <Anchor
          onClick={() => {
            if (window.history.length > 1) {
              window.history.back();
            } else {
              setBackDisabled(true);
            }
          }}
          color="dark-1"
          size="medium"
          disabled={backDisabled}
        >
          <LinkPrevious />
        </Anchor>
        {title}
        <Nav direction="row" justify="end">
          <Anchor onClick={user ? logout : login}>
            {user ? <Logout size="medium" /> : <Login size="medium" />}
          </Anchor>
        </Nav>
      </Header>
      <Main>{children}</Main>
    </>
  );
};

export default Wrapper;
