import React from "react";
import { Box, Heading, Text } from "grommet";
import { NlndPageProps } from "./types";
import PageWrapper from "./PageWrapper";

const Page = ({}: NlndPageProps<{}>) => {
  return (
    <Box pad="small">
      <Heading level="3" textAlign="center">
        How To Use
      </Heading>
      <ul>
        <Text as="li" margin={{ vertical: "small" }}>
          Download and install the extension (Works on Chrome and Firefox)
        </Text>
        <Text as="li" margin={{ vertical: "small" }}>
          Pin the icon to your taskbar
        </Text>
        <Text as="li" margin={{ vertical: "small" }}>
          Click on the icon for this extension
        </Text>
        <Text as="li" margin={{ vertical: "small" }}>
          Select a post on the page (the selected post will be highlighted)
        </Text>
        <Text as="li" margin={{ vertical: "small" }}>
          A popup should load with details about the selected post
        </Text>
      </ul>
    </Box>
  );
};

const WrappedPage = (props: NlndPageProps<{ postId: string }>) => (
  <PageWrapper {...props} title="Welcome">
    <Page {...props} />
  </PageWrapper>
);

export default WrappedPage;
