import React, { useCallback, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { UserContext } from "./nlndx/types";
import { Layer, Box } from "grommet";
import PageLoading from "./Loading";

const useUser = (): UserContext => {
  const [user, setUser] = useState<firebase.User | null>(null);

  const [signInElement, setSignInElement] = useState<HTMLDivElement | null>(
    null
  );
  const [loggingIn, setLoggingIn] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [userReady, setUserReady] = useState(false);

  useEffect(() => {
    const firebaseConfig = {
      apiKey: process.env.GATSBY_FIREBASE_API_KEY,
      authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
      storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.GATSBY_FIREBASE_APP_ID,
    };

    if (!firebase.apps.length) {
      const firebaseApp = firebase.initializeApp(firebaseConfig);
      firebaseApp.auth().onAuthStateChanged(function (user) {
        setUser(user);
        setUserReady(true);
      });
    }
  }, []);

  useEffect(() => {
    if (!loggingIn || !signInElement) {
      return;
    }

    setPageLoading(true);
    import("firebaseui").then((firebaseUiModule) => {
      let firebaseUi = firebaseUiModule.auth.AuthUI.getInstance();
      if (!firebaseUi) {
        firebaseUi = new firebaseUiModule.auth.AuthUI(firebase.auth());
      }

      setLoggingIn(true);
      firebaseUi.start(signInElement, {
        signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
        signInFlow: "popup",
        callbacks: {
          signInSuccessWithAuthResult: () => {
            setLoggingIn(false);
            return false;
          },
          uiShown: () => {
            setPageLoading(false);
          },
          signInFailure: async (error) => {
            setLoggingIn(false);
          },
        },
      });
    });
  }, [loggingIn, signInElement]);

  useEffect(() => {
    if (user && loggingIn) {
      setLoggingIn(false);
    }
  }, [user, loggingIn]);

  const logout = useCallback(() => {
    if (user) {
      firebase.auth().signOut();
      return;
    }
  }, [user]);

  const login = useCallback(() => {
    if (user) {
      return;
    }
    setLoggingIn(true);
  }, [user]);

  return {
    user: user || undefined,
    login,
    logout,
    loading: pageLoading,
    setSignInElement,
    showLoginOverlay: loggingIn,
    ready: userReady
  };
};

export const LoginOverlay = ({ context }: { context: UserContext }) => {
  const { showLoginOverlay, loading, setSignInElement } = context;

  return showLoginOverlay ? (
    <Layer>
      <Box ref={setSignInElement} />
      {loading && <PageLoading />}
    </Layer>
  ) : null;
};

export default useUser;
