import { Box, Spinner } from "grommet";
import React from "react";

const PageLoading = () => (
    <Box direction="row" justify="center" height="80px" margin={{vertical: "large"}}>
      <Spinner size="large" color="dark-1" />
    </Box>
);

export default PageLoading;
  