import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Text,
} from "grommet";
import { Trash } from "grommet-icons";
import { FetchState, NlndPageProps } from "./types";
import PageLoading from "../Loading";
import Error from "../Error";
import { format, parseISO } from "date-fns";
import PageWrapper from "./PageWrapper";

const Page = ({ user: userContext }: NlndPageProps<{}>) => {
  const [subsResponse, setSubsResponse] = useState<any>();
  const { user, login, ready: userReady } = userContext;
  const [fetchState, setFetchState] = useState<FetchState>("LOADING");

  useEffect(() => {
    user?.getIdTokenResult()?.then((idToken) => {
      setFetchState("LOADING");
      fetch("/api/nlndx/subscriptions", {
        headers: {
          Authorization: "Bearer " + idToken.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
        method: "POST",
      })
        .then((res) => res.json())
        .then((json) => {
          setSubsResponse(json);
          setFetchState("COMPLETE");
        })
        .catch((err) => {
          console.log(err);
          setFetchState("ERROR");
        });
    });
  }, [user]);

  const unsubscribe = useCallback(
    (postId) => {
      user?.getIdTokenResult()?.then((idToken) => {
        setFetchState("LOADING");
        fetch("/api/nlndx/subscribe", {
          headers: {
            Authorization: "Bearer " + idToken.token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            postId: postId,
            unsubscribe: true,
          }),
          method: "POST",
        })
          .then((res) => res.json())
          .then(() => {
            setFetchState("COMPLETE");
            const replacement = {
              subscriptions: subsResponse?.subscriptions?.filter(
                (s: any) => s.postId !== postId
              ),
            };
            setSubsResponse(replacement);
          })
          .catch((err) => {
            console.log(err);
            setFetchState("ERROR");
          });
      });
    },
    [user, subsResponse]
  );

  if (fetchState === "ERROR") {
    return <Error />;
  }

  if (!user && userReady) {
    return (
      <Box>
        Please <Button onClick={login}>Sign In</Button> To View Subscriptions
      </Box>
    );
  }

  if (fetchState === "LOADING" || !userReady) {
    return <PageLoading />;
  }

  if (!subsResponse?.subscriptions?.length) {
    return (
      <Box>
        <Text margin={{ vertical: "large" }} textAlign="center">
          You have no subscriptions
        </Text>
      </Box>
    );
  }

  return (
    <Box pad="medium">
      {subsResponse?.subscriptions?.map((sub: any) => {
        const created = sub.created ? parseISO(sub.created) : null;
        const createdDisplay = created ? format(created, "d MMM yyyy") : null;

        return (
          <Card
            background="light-1"
            key={sub.id}
            margin={{ vertical: "small" }}
          >
            <CardHeader pad="medium">
              <Text size="small">{`Created: ${
                createdDisplay || "Unknown"
              }`}</Text>
            </CardHeader>
            <CardBody pad="medium">
              {sub.postId} - {sub.id}
            </CardBody>
            <CardFooter
              pad={{ horizontal: "small" }}
              background="light-2"
              justify="end"
            >
              <Button
                tip="Delete"
                icon={<Trash />}
                onClick={() => unsubscribe(sub.postId)}
              />
            </CardFooter>
          </Card>
        );
      })}
    </Box>
  );
};

const WrappedPage = (props: NlndPageProps<{ postId: string }>) => (
  <PageWrapper {...props} title="Manage Subscriptions">
    <Page {...props} />
  </PageWrapper>
);

export default WrappedPage;
