import React, { useState, useEffect } from "react";
import { Box } from "grommet";
import { NlndPageProps } from "./types";
import PageWrapper from "./PageWrapper";

const Page = ({ postId }: NlndPageProps<{ postId: string }>) => {
  const [apiResponse, setApiResponse] = useState<any>();

  useEffect(() => {
    fetch("/api/nlndx/posts", {
      body: JSON.stringify({
        postId: postId,
      }),
      method: "POST",
    })
      .then((res) => res.json())
      .then((json) => setApiResponse(json))
      .catch((err) => console.log(err));
  }, [postId]);

  return <Box pad="20px">{JSON.stringify(apiResponse)}</Box>;
};

const WrappedPage = (props: NlndPageProps<{ postId: string }>) => (
  <PageWrapper {...props} title="Conversation">
    <Page {...props} />
  </PageWrapper>
);

export default WrappedPage;
