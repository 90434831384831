import React from "react";
import { Box, Text } from "grommet";

const Error = ({ message } : { message?: string } ) => (
    <Box direction="row" justify="center" height="80px">
      <Text color="status-error">{message || 'Sorry, we encountered an error'}</Text>
    </Box>
);

export default Error;
  