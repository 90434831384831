import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Heading, Text, TextInput } from "grommet";
import { Link, useNavigate } from "@reach/router";
import { Notification } from "grommet-icons";

import PageLoading from "../Loading";
import Error from "../Error";
import { FetchState, NlndPageProps } from "./types";
import PageWrapper from "./PageWrapper";

const Page = ({
  postId,
  user: userContext,
}: NlndPageProps<{ postId: string }>) => {
  const navigate = useNavigate();
  const [subsResponse, setSubsResponse] = useState<any>();
  const [fetchState, setFetchState] = useState<FetchState>("LOADING");

  const { user, login, ready: userReady } = userContext;

  useEffect(() => {
    user?.getIdTokenResult()?.then((idToken) => {
      fetch("/api/nlndx/subscriptions", {
        headers: {
          Authorization: "Bearer " + idToken.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postId: postId,
        }),
        method: "POST",
      })
        .then((res) => res.json())
        .then((json) => {
          setSubsResponse(json);
          setFetchState("COMPLETE");
        })
        .catch((err) => {
          console.log(err);
          setFetchState("ERROR");
        });
    });
  }, [user]);

  const subscribe = useCallback(() => {
    setFetchState("LOADING");
    user?.getIdTokenResult()?.then((idToken) => {
      fetch("/api/nlndx/subscribe", {
        headers: {
          Authorization: "Bearer " + idToken.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postId: postId,
        }),
        method: "POST",
      })
        .then((res) => res.json())
        .then(() => navigate("/nlndx/subscription"))
        .catch((err) => console.log(err));
    });
  }, [user]);

  if (fetchState === "ERROR") {
    return <Error />;
  }

  if (!user && userReady) {
    return (
      <Box>
        Please <Button onClick={login}>Sign In</Button> To View Subscriptions
      </Box>
    );
  }

  if (fetchState === "LOADING" || !userReady) {
    return <PageLoading />;
  }

  return subsResponse?.subscriptions?.length ? (
    <Box pad="medium">
      <Heading level="2" textAlign="center" color="dark-2">
        All Set!
      </Heading>
      <Box dir="row" justify="center">
        You are already receiving updates for this post.{" "}
        <Link to="/nlndx/subscription">Manage Subscriptions</Link>
      </Box>
    </Box>
  ) : (
    <Box pad="medium">
      <Heading level="2" textAlign="center" color="dark-2">
        Confirm Subscription
      </Heading>
      <Button
        primary
        onClick={subscribe}
        icon={<Notification />}
        label="Subscribe"
        margin={{ vertical: "large" }}
        reverse
        size="large"
        color="accent-4"
      />
      <Text textAlign="center">{`By subscribing, you are consenting to our sending you emails at ${user?.email}`}</Text>
    </Box>
  );
};

const WrappedPage = (props: NlndPageProps<{ postId: string }>) => (
  <PageWrapper {...props} title="Get Updates">
    <Page {...props} />
  </PageWrapper>
);

export default WrappedPage;
